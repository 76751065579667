.loginNav{
  text-decoration: none;
  padding: 0px;
}
.textField:lang(fa) {
  direction: rtl;
  color:#7C7C7C;
}

.textField:lang(en) {
  direction: ltr;
  color:#7C7C7C;
}

.textField:lang(en) .MuiInputBase-root fieldset{
  border : 1px solid#D9D9D9;
  border-radius: 4px;
}


.textField:lang(fa) label {
  transform-origin: top right;
  left: auto;
  right: 30px;
  color :#7C7C7C !important;
  direction: rtl;
}

.textField:lang(en) label {
  transform-origin: top left;
  left: 0;
  right: auto;
  color:#7C7C7C !important;
  direction: ltr;
}

.textField:lang(en) fieldset legend {
  text-align: left;
}

.textField:lang(fa) fieldset legend {
  text-align: right;
}
.g-recaptcha{
  border-radius: 4px;
  transform:scale(1);
  }

.g-recaptcha-login{
    transform:scale(1);
  }

  @media (max-width: 900px) and (min-width: 600px) {
    .g-recaptcha{
      transform:scale(0.80);
    }
    .g-recaptcha-login{
      transform:scale(0.65);
    }
  }

  @media (max-width:599px) and (min-width: 450px) {
    .g-recaptcha-login{
      transform:scale(1);
    }
  }
  @media (max-width:450px) and (min-width: 320px) {
    .g-recaptcha-login{
      transform:scale(0.70);
    }
  }

  