.navlink {
  padding:0px 8px 0px;
  border-bottom:2px solid transparent;
  transition: 0.3s all;
  cursor: pointer;
  text-decoration: none;
}

.navlink.active p {
  color:#0C5463;
}

.navlink p:hover{
  color: #3D7884;
}

.nav_link {
  transition: 0.3s all;
  cursor: pointer;
  text-decoration: none;
  display:flex;
  flex-direction: row;
}
.navlink_humber{
  transition: 0.3s all;
  cursor: pointer;
  text-decoration: none;
  display:flex;
  flex-direction: row;
}
@media (max-width:1000px) {
  .navlink {
    padding:0px 10px 0px;
  }
}
@media (max-width:900px) {
  #nav_menu{
    background:unset;
    box-shadow: none;
  }
  #nav_menu{
    background:rgba(105,99,99,0.6);
    top: 60px;
  }
  #nav_menu:lang(fa){
    top:60px !important;
    left:0 !important;
    border-radius: 0px !important;
  }
  #nav_menu:lang(en) {
    top:60px !important;
    right:0px !important;
    left: unset !important;
    border-radius: 0px !important;
  }
}

#mobile_menu:lang(fa) {
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 200;
}

#mobile_menu:lang(en) {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 200;
}

@media only screen and (max-width : 900px) {
  #mobile_menu:lang(fa) {
    position: fixed;
    top: 0px;
    right: 0;
  }

  #mobile_menu:lang(en) {
    position: fixed;
    top: 0px;
    left: 0;
  }
}
