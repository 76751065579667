.text_field:lang(fa) {
  direction: rtl;
  color:#7C7C7C;
}

.text_field:lang(en) {
  direction: ltr;
  color:#7C7C7C;
}

.text_field .MuiInputBase-root fieldset{
  border: none ;
}

.text_field .MuiInputBase-root input{
  padding: 16.5px 8px;
}

.text_field:lang(fa) label {
  transform-origin: top right;
  left: auto;
  right: 28px;
  color :#7C7C7C !important;
  direction: rtl;
}

.text_field:lang(en) label {
  transform-origin: top left;
  left: 0;
  right: auto;
  color :#7C7C7C !important;
  direction: ltr;
}

.text_field fieldset legend {
  text-align: center;
}
@media (max-width: 665px) and ( min-width : 600px) {
  .headerTitle:lang(fa){
    font-size: 11px !important;
  }
  .headerTitle2:lang(fa){
    font-size: 10px !important;
  }
  .headerButton{
    width: 150px !important;
    height: 40px !important;
    font-size: 11px !important;
  }
}
@media (max-width: 740px) and ( min-width : 665px) {
  .headerTitle:lang(fa){
    font-size: 14px !important;
  }
  .headerTitle2:lang(fa){
    font-size: 12px !important;
  }
  .headerButton{
    width: 170px !important;
    height: 45px !important;
  }
}